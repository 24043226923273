import { stringify } from 'querystring';

import { useEffect } from 'react';

import { MenuItem, useTheme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { enqueueSnackbar } from 'notistack';
import { useTranslations } from 'next-intl';

import { ENGLISH_ONLY_PAGES } from '@/common/constants';
import { FeatureFlags } from '@/common/enums';
import useLocale from '@/hooks/useLocale';

const LocaleSwitcher = () => {
  const { locale, defaultLocale } = useLocale();
  const { pathname, asPath, push, query } = useRouter();
  const theme = useTheme();
  const tg = useTranslations('Generic');

  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  useEffect(() => {
    if (!Object.hasOwn(ENGLISH_ONLY_PAGES, pathname)) {
      return;
    }
    if (locale === defaultLocale) {
      return;
    }
    enqueueSnackbar(tg('alert.englishOnlyPage'));
    push({ pathname, query }, asPath, { locale: defaultLocale });
  }, [pathname, locale, push, query, asPath, tg]);

  function handleLocaleChange(event: SelectChangeEvent): void {
    let search = stringify(query);
    if (search.length > 0) {
      search = '?' + search;
    }
    if (Object.hasOwn(ENGLISH_ONLY_PAGES, pathname)) {
      enqueueSnackbar(tg('alert.englishOnlyPage'));
      return;
    }
    dayjs.locale(event.target.value);
    push({ pathname, query }, asPath, { locale: event.target.value });
  }

  const isLithuanianEnabled = useFeatureFlagEnabled(FeatureFlags.showLithuanian);

  return (
    <Select
      onChange={handleLocaleChange}
      value={locale}
      variant="standard"
      sx={{ color: 'white', borderColor: theme.palette.common.white }}
      IconComponent="div"
      className="language-selection"
      data-testid="language-selection"
    >
      <MenuItem value="en">ENG</MenuItem>
      <MenuItem value="et">EST</MenuItem>
      {isLithuanianEnabled && <MenuItem value="lt">LIT</MenuItem>}
    </Select>
  );
};

export default LocaleSwitcher;
