import { useEffect } from 'react';

import { Grid } from '@mui/material';
import { useTranslations } from 'next-intl';
import Head from 'next/head';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';

import { FeatureFlags } from '@/common/enums';
import BrowserLanguageAlert from '@/components/BrowserLanguageAlert';
import Header from '@/components/Header';
import { selectCurrentUser } from '@/features/auth/authSlice';
import useLocale from '@/hooks/useLocale';

export interface IPrimaryLayout extends React.ComponentPropsWithoutRef<'div'> {
  justify?: 'items-center' | 'items-start';
}

const PrimaryLayout: React.FC<IPrimaryLayout> = ({ children }) => {
  const tg = useTranslations('Generic');

  const user = useSelector(selectCurrentUser);
  const { locale } = useLocale();

  useEffect(() => {
    if (!user || !user.company) {
      return;
    }

    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
      return;
    }

    // Only send non PII data to GTM
    TagManager.dataLayer({
      dataLayer: {
        event: 'load',
        company: {
          name: user.company.name,
          registryNumber: user.company.registryNumber,
          country: user.company.country,
          naceCode: user.company.naceCode,
          isRequester: user.company.isRequester,
          isInternal: user.company.isInternal,
        },
        locale,
      },
      dataLayerName: 'PageDataLayer',
    });
  }, [user, locale]);

  const isLanguageBarEnabled = useFeatureFlagEnabled(FeatureFlags.browserLanguageBar);

  if (Object.keys(tg('ok')).length == 0) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Esgrid</title>
      </Head>
      <Header />
      <Grid container className="page-container" justifyContent="center">
        <Grid item xs={12} xxl={10}>
          {isLanguageBarEnabled && <BrowserLanguageAlert />}
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default PrimaryLayout;
